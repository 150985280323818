<template>
  <content-layout :breadcrumbs-items="breadcrumbsItems">
    <v-card class="my-6" outlined rounded="lg" :max-width="1000">
      <v-card-title>{{
        $t('customer.manageAutomations.orderAutomationsTitle')
      }}</v-card-title>
      <v-card-text
        class="d-flex flex-column"
        style="row-gap: 20px; position: relative"
      >
        <div class="d-flex flex-column" style="row-gap: 10px">
          <div
            class="text-body-1"
            v-html="
              $t('customer.manageAutomations.orderAutomationEmailFieldLabel')
            "
          />

          <div class="d-flex mt-3" style="column-gap: 40px">
            <cz-form-field label="שליחה מכתובת מייל">
              <div
                class="d-flex align-center"
                v-if="!editMode"
                style="column-gap: 10px"
              >
                <div class="text-title font-weight-semibold info--text">
                  {{
                    `${account.poOutboundEmailPrefix}@${account.authorizedDomain}`
                  }}
                </div>
                <cz-button
                  text
                  :icon-src="mdiPencil"
                  @click="editMode = true"
                  small
                  tooltip="עריכת כתובת"
                />
              </div>

              <cz-input
                v-else
                v-model="accountLocal.poOutboundEmailPrefix"
                dir="ltr"
                dense
                hide-details
              >
                <template #append>
                  <span class="ml-2 mt-1">
                    {{ `@${account.authorizedDomain}` }}</span
                  >
                </template>
              </cz-input>
            </cz-form-field>
            <cz-icon
              :src="mdiArrowLeft"
              :size="30"
              color="primaryText"
              class="mt-4"
            />
            <cz-form-field label="שליחה אל כתובת מייל">
              <div
                class="text-title font-weight-semibold info--text"
                v-if="account"
              >
                {{ account.poInboundEmail }}
              </div>
            </cz-form-field>
          </div>
          <div
            class="d-flex align-center mt-4"
            style="column-gap: 15px"
            v-if="editMode"
          >
            <cz-button
              v-if="editMode"
              :title="$t('common.save')"
              class="px-6"
              color="info"
              :disabled="!saveEnabled"
              :loading="saving"
              @click="saveAccount"
            />

            <cz-button
              v-if="editMode && account.poOutboundEmailPrefix"
              text
              color="info"
              :title="$t('common.cancel')"
              @click="editMode = false"
            />
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-card outlined rounded="lg" :max-width="1000">
      <v-card-title>
        {{ $t('customer.manageAutomations.invoicesAutomationsTitle') }}
      </v-card-title>
      <v-card-text>
        <div class="d-flex flex-column" style="row-gap: 10px">
          <div class="text-body-1">
            {{
              $t(
                'customer.manageAutomations.invoicesAutomationsEmailFieldLabel'
              )
            }}
          </div>
          <div
            class="text-title font-weight-semibold info--text"
            v-if="account"
          >
            {{ account.invoicesInboundEmail }}
          </div>
        </div>
      </v-card-text>
    </v-card>
  </content-layout>
</template>

<script>
import { CzInput, CzFormField, CzIcon, CzButton } from '@/components';
import { mapFields } from 'vuex-map-fields';
import { mdiArrowLeft, mdiPencil } from '@mdi/js';
import isEqual from 'lodash.isequal';
import { updateAccount } from '@/core/api';
import { mapActions } from 'vuex';
export default {
  name: 'ManageInvoicesPage',
  components: {
    ContentLayout: () => import('@/layouts/ContentLayout.vue'),
    CzInput,
    CzButton,
    CzIcon,
    CzFormField
  },
  created() {
    this.accountLocal = structuredClone(this.account);
    this.editMode = !this.account.poOutboundEmailPrefix;
  },
  computed: {
    ...mapFields('auth', ['account']),
    breadcrumbsItems() {
      return [
        {
          text: this.$t('common.homepage'),
          to: {
            name: 'customer'
          },
          disabled: false,
          exact: true,
          isHome: true
        },
        {
          text: this.$t('customer.manageAutomationsTitle'),
          disabled: true,
          exact: true
        }
      ];
    },
    invoicesInboundEmail() {
      return this.authorizedDomain;
    },
    saveEnabled() {
      return !isEqual(this.accountLocal, this.account);
    }
  },
  data() {
    return {
      mdiPencil,
      mdiArrowLeft,
      accountLocal: null,
      saving: false,
      editMode: false
    };
  },
  methods: {
    ...mapActions('ui', ['showSuccessToastMessage', 'showErrorToastMessage']),
    async saveAccount() {
      try {
        this.saving = true;
        this.account = await updateAccount(this.account._id, {
          poOutboundEmailPrefix: this.accountLocal.poOutboundEmailPrefix
        });
        this.accountLocal = structuredClone(this.account);
        this.showSuccessToastMessage(this.$t('common.dataSavedSuccess'));
        this.editMode = false;
      } catch (error) {
        this.showErrorToastMessage(error.message);
      } finally {
        this.saving = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
